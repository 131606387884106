import React from "react"
import styled from "styled-components"
import FullWidthSingleColor from "../components/FullWidthSingleColor"
import NewsCard from "../components/NewsCard"
import { ContentfulEventCard } from "../shared/ContentfulTypes"

type RelatedArticleProps = {
  data: ContentfulEventCard[]
  relatedArticlesMode: "Dark" | "Light"
  relatedArticlesHeading: string
}
const RelatedArticles: React.FC<RelatedArticleProps> = ({
  data,
  mode,
  heading,
}) => {
  return (
    <Section mode={mode}>
      <FullWidthSingleColor mode={mode} />
      <h2>{heading}</h2>
      <Wrapper>
        {data.map((card) => {
          return <NewsCard mode={mode} data={card} />
        })}
      </Wrapper>
    </Section>
  )
}
const Section = styled.section`
  position: relative;

  padding-top: 8rem;
  padding-bottom: 10rem;
  h2 {
    color: white;
    margin-bottom: 2rem;
  }
`
const Wrapper = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
`
export default RelatedArticles
