import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import FullWidthCover from "../components/FullWidthCover"
import PageWrapper from "../components/PageWrapper"
import { embedOptions } from "../sections/HeroNew"
import RelatedArticles from "../sections/RelatedArticles"

import { ContentfulRichText } from "../shared/ContentfulTypes"
import { Title7 } from "../shared/Typograpy"
import { useResizeObserver } from "../utils/ResizeObserver"
import { richtextDefaultOptions } from "../components/RichTextOptions"

type Data = {
  contentfulNewsEvents: {
    heading: string
    priceRichText: ContentfulRichText
    type: "News" | "Event"
    body: ContentfulRichText
    thumbnailHeading: string
    description: string
    slug: string
    month?: string
    day?: string
    time?: string
    year?: string
    endMonth?: string
    endDay?: string
    endTime?: string
    endYear?: string
    price?: string
    place?: string
    relatedArticles: any[]
    relatedArticlesMode?: "Dark" | "Light"
    relatedArticlesHeading?: string
    thumbnailImage: {
      desription: string
      gatsbyImageData: IGatsbyImageData
    }
  }
}

type NewsTemplateProps = {
  data: Data
}
const NewsTemplate: React.FC<NewsTemplateProps> = ({ data }) => {
  const {
    type,
    heading,
    body,
    thumbnailHeading,
    description,
    slug,
    priceRichText,
    thumbnailImage,
    endDay,
    endTime,
    endMonth,
    endYear,
    month,
    day,
    time,
    year,
    place,
    relatedArticles,
    relatedArticlesHeading,
    relatedArticlesMode,
  } = data.contentfulNewsEvents
  const [linkCopied, setLinkCopied] = useState(false)

  const [url, setUrl] = useState("")

  const heroImageRef = useRef(null)

  const [width, height] = useResizeObserver(heroImageRef)
  const [isBrowser, setIsBrowser] = useState(false)

  const handleClipboard = () => {
    if (typeof window !== undefined) {
      navigator.clipboard.writeText(window.location.href)
      setLinkCopied(true)
    }
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      setUrl(window.location.href)
      setIsBrowser(true)
    }
  }, [])

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: thumbnailHeading || heading,

          url,
        })
        .then(() => {
          // console.log("Successfully shared")
        })
        .catch((error) => {
          console.error("Something went wrong", error)
        })
    }
    return
  }

  const hasEndDay = endDay !== day
  const hasEndMonth = endMonth !== month
  const hasEndYear = endYear !== year

  const hasEndTime = endTime !== undefined && endTime !== time

  let hasEndDate = hasEndDay || hasEndMonth || hasEndYear

  if (endDay === undefined || endMonth === undefined || endYear === undefined) {
    hasEndDate = false
  }
  const isDevelopment = process.env.GATSBY_DEVELOP
  const shouldIndex = isDevelopment ? false : true

  return (
    <PageWrapper
      indexing={shouldIndex}
      seo={{
        seoTitle: thumbnailHeading || heading,
        seoDescription: description,
        seoImage: thumbnailImage?.url,
        slug: slug,
      }}
    >
      <StyledHero>
        <div></div>
        <HeroImageWrapper ref={heroImageRef}>
          {thumbnailImage ? (
            <GatsbyImage
              style={{ width: "100%", height: "100%", maxHeight: "60rem" }}
              imgStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
              alt={thumbnailImage.description || "focalpay"}
              image={thumbnailImage.gatsbyImageData}
            />
          ) : (
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
              alt="focalpay"
              src="../images/placeholder.png"
            />
          )}
        </HeroImageWrapper>
        <StyledText padding={height}>
          <h1>{heading}</h1>
          <p>{description}</p>
        </StyledText>
      </StyledHero>
      <StyledArticleSection>
        <MobileShare onClick={handleShare}>
          <StaticImage
            style={{ height: "2rem", width: "2rem", marginRight: "1rem" }}
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            alt="share"
            src="../images/share2.svg"
          />
          <div>{`Share ${type === "News" ? "article" : "event"}`}</div>
        </MobileShare>
        <StyledArticle>
          {body && renderRichText(body, embedOptions)}
        </StyledArticle>
        <StyledAside>
          {type === "Event" && (
            <Box>
              <BoxHeading>About the event</BoxHeading>
              {day && (
                <Row>
                  <Title7>{hasEndDate ? "Starts:" : "Date:"}</Title7>
                  <p>{`${day} ${month} ${year} ${
                    hasEndDate ? ` - ${time}` : ""
                  }`}</p>
                </Row>
              )}
              {day && (
                <Row>
                  <Title7>{hasEndDate ? "Ends:" : "Time:"}</Title7>

                  <p>
                    {hasEndDate
                      ? `${endDay} ${endMonth}  ${endYear} - ${endTime}`
                      : `${hasEndTime ? time + " - " + endTime : time}`}
                  </p>
                </Row>
              )}
              <Row>
                <Title7>Place:</Title7>
                <p>{place}</p>
              </Row>
              <Row>
                <Title7>Price:</Title7>
                <p>
                  {priceRichText &&
                    renderRichText(priceRichText, richtextDefaultOptions)}
                </p>
              </Row>
            </Box>
          )}

          <Box2>
            <BoxHeading>Share {type.toLowerCase()}</BoxHeading>
            <SocialMedia>
              <ImageWrapper
                target="_blank"
                href={
                  url &&
                  `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=Some%20Title&summary=Some%20Summary&source=Focalpay`
                }
              >
                <StaticImage
                  alt="share to linkedin"
                  src="../images/linkedin.svg"
                />
              </ImageWrapper>
              {/* <ImageWrapper>
                <StaticImage
                  alt="share to linkedin"
                  src="../images/twitter.svg"
                />
              </ImageWrapper>
              <ImageWrapper>
                <StaticImage
                  alt="share to linkedin"
                  src="../images/slack.svg"
                />
              </ImageWrapper>
              <ImageWrapper>
                <StaticImage
                  alt="share to linkedin"
                  src="../images/messenger.svg"
                />
              </ImageWrapper>
              <ImageWrapper>
                <StaticImage
                  alt="share to linkedin"
                  src="../images/facebook.svg"
                />
              </ImageWrapper> */}
            </SocialMedia>

            <Share>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={isBrowser ? `mailto:?body=${window.location.href}` : ""}
              >
                <p>Send link by email</p>
              </a>
            </Share>
            <Share>
              <p onClick={handleClipboard}>
                {linkCopied ? "Link copied" : "Copy link"}
              </p>
            </Share>
          </Box2>
        </StyledAside>
        <MobileShare onClick={handleShare}>
          <StaticImage
            style={{ height: "2rem", width: "2rem", marginRight: "1rem" }}
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            alt="share"
            src="../images/share2.svg"
          />
          <div>{`Share ${type === "News" ? "article" : "event"}`}</div>
        </MobileShare>
      </StyledArticleSection>

      {relatedArticles && (
        <RelatedArticles
          heading={relatedArticlesHeading}
          mode={relatedArticlesMode}
          data={relatedArticles}
        />
      )}
    </PageWrapper>
  )
}
const MobileShare = styled.a`
  text-decoration: none;
  font-size: 1.6rem;
  color: #7700e6;
  div {
    display: inline-block;
    margin-top: -0.4rem;
    font-size: 1.6rem;
    font-weight: 900;
  }
  @media (min-width: 1100px) {
    display: none;
  }
`

const StyledHero = styled.section`
  width: 100%;
  min-height: 60rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  /* h1 {
    font-size: 2.4rem;
    padding-top: 8rem;
  } */
  @media (min-width: 1440px) {
    max-height: 60rem;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`
const HeroImageWrapper = styled.div`
  position: absolute;
  z-index: -2;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  height: 80%;

  @media (min-width: 1440px) {
    max-height: 60rem;
    min-height: 60rem;
    width: 70vw;
    right: 40%;
    left: unset;

    transform: unset;
  }
`

type StyledTextProps = {
  padding: number
}
const StyledText = styled.div<StyledTextProps>`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 2rem;
    @media (min-width: 700px) {
      max-width: 80%;
    }
    @media (min-width: 1440px) {
      max-width: 80%;
    }
  }
  justify-content: center;
  padding-top: ${(props) => `${props.padding / 100 + 2}rem`};

  position: relative;

  max-width: 1032px;
  margin: 0 auto;
  &:after {
    content: "";
    position: absolute;
    background: rgb(255, 255, 255);

    left: 0;
    z-index: -1;

    width: 200vw;
    height: 100%;
    left: 50%;

    transform: translateX(-50%) rotate(10deg);
    top: 10%;
    @media (min-width: 1440px) {
      top: 0;
      height: 100%;
      transform: skew(-10deg);
      left: -10%;
    }
  }
  h1,
  p {
    margin-bottom: 2rem;
  }
`
const StyledArticleSection = styled.section`
  display: grid;
  max-width: 1032px;
  margin: 0 auto;
  min-height: 80rem;
  padding-bottom: 5rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 2rem;

  @media (min-width: 1440px) {
    grid-template-columns: 1fr 30rem;
    grid-gap: 6.4rem;
    padding-top: 9.6rem;
  }

  .arrow {
  }
`
const StyledAside = styled.aside``

const BoxHeading = styled.h6`
  margin-bottom: 2rem;
  color: #242424;
`
const Box = styled.div`
  position: relative;
  padding: 2rem 0rem;

  &:after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--grey);
  }
  @media (min-width: 1440px) {
    &:after {
      content: none;
    }
    background-color: #e6f0f0;
    padding: 3.2rem;
    margin-bottom: 3.2rem;
    border-radius: 5px;
    box-shadow: var(--boxShadow);
    color: var(--purple);
    p {
      font-size: 1.6rem;
    }
  }
`
const Box2 = styled(Box)`
  display: none;
  @media (min-width: 1440px) {
    display: block;
  }
`
const Row = styled.div`
  display: flex;
  @media (min-width: 1400px) {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
  }
  h6 {
    margin-right: 1rem;
  }
`
const SocialMedia = styled.div`
  display: flex;
  margin-bottom: 2rem;
`

const ImageWrapper = styled.a`
  text-decoration: none;
  margin-right: 1.5rem;
  cursor: pointer;
  min-width: 2rem;
`

const Share = styled.div`
  a {
    text-decoration: none;
    font-size: unset;
  }

  cursor: pointer;
  margin-bottom: 1rem;
`
const StyledArticle = styled.article`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 2rem;
  }
  p:empty {
    display: unset;
  }
  .slick-slider {
    margin-bottom: 7rem;
  }
`
export const query = graphql`
  query MyNewsPageQuery($id: String) {
    contentfulNewsEvents(id: { eq: $id }) {
      priceRichText {
        raw
      }
      heading
      type
      thumbnailHeading
      thumbnailImage {
        url
        description
        gatsbyImageData
      }
      description
      month: date(formatString: "MMM")
      day: date(formatString: "DD")
      time: date(formatString: "HH:mm")
      year: date(formatString: "yyyy")
      endMonth: dateTo(formatString: "MMM")
      endDay: dateTo(formatString: "DD")
      endTime: dateTo(formatString: "HH:mm")
      endYear: dateTo(formatString: "yyyy")
      place
      slug
      relatedArticles {
        ...Event
      }
      relatedArticlesHeading
      relatedArticlesMode
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            file {
              url
            }
          }
          ... on ContentfulSalesforceForm {
            ...SalesforceForm
          }
          ... on ContentfulSlider {
            ...Slider
          }
          ... on ContentfulForm {
            contentful_id
            ...Form
          }
        }
      }
    }
  }
`

export default NewsTemplate
